import auth from '@/apis/drupal/auth';
import { useAuthStore } from '@/stores/auth';
import { EventMap, EventListener } from '@/util/eventListener';
import { NumericId, User } from '@/util/formatters';
import axios, { AxiosResponse } from 'axios';
import { set } from 'date-fns';
import { useEffect, useRef } from 'react';

interface UseSwagMessagesOptions {
  onToggleFullscreen?: () => void;
  onShowShareDialog?: () => void;
  onShowLoginDialog?: () => void;
}

export type SWAGSDKEvents = EventMap & {
  'sendToken': () => void
}

class SWAGSDKEventListener extends EventListener<SWAGSDKEvents> {}
export const swagSdkEventListener = new SWAGSDKEventListener();

async function requestToken (uid: any, displayName: string) {
  let token;
  try {
    const queryParams = new URLSearchParams({
      jwt: '1',
      ag_uid: uid,
      ag_user: displayName,
    });
    const response = await axios.get(
      process.env.NEXT_PUBLIC_SWAG_API_URL + 
      '/token?' + 
      queryParams.toString(),
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      }
    );
    token = response.data;
  } catch {
    throw new Error('Failed to get token from API');
  }

  let user;
  try {
    const response = await axios.get(process.env.NEXT_PUBLIC_SWAG_API_URL + '/user', {
      headers: {
        'x-member-token': token,
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      }
    });
    user = response.data;
  } catch {
    throw new Error('Failed to get user from API');
  }

  return btoa(JSON.stringify(user));
}

export function useSwagMessages (options: UseSwagMessagesOptions = {}) {
  const [ authState ] = useAuthStore();

  const shouldSendTokenRef = useRef(false);

  const completeTokenRequest = async (source: Window, origin: string) => {
    if (!authState.ready) {
      const payload = {
        eventName: 'onTokenReceived',
        message: 'Auth state not ready',
      };
      source.postMessage(
        JSON.stringify(payload), 
        origin
      );
      return;
    };

    let loginToken;
    try {
      loginToken = await requestToken(
        authState.user?.uid || '', 
        authState.user?.displayName || ''
      );
    } catch (err) {
      const payload = {
        eventName: 'onTokenError',
        message: err.message,
      };
      source.postMessage(
        JSON.stringify(payload), 
        origin
      );
      return;
    }

    const payload = {
      eventName: 'onTokenReceived',
      message: loginToken,
    };
    source.postMessage(
      JSON.stringify(payload), 
      origin
    );
    return;
  };

  useEffect(() => {
    let messageHandler;

    window.addEventListener('message', messageHandler = async (event) => {
      if (!event || typeof event.data !== 'string') return;

      try {
        const { eventName } = JSON.parse(event.data);

        switch (eventName) {
        case 'toggleFullscreen': {
          options.onToggleFullscreen?.();
          return;
        }
        case 'showShareDialog': {
          options.onShowShareDialog?.();
          return;
        }
        case 'showLoginDialog': {
          options.onShowLoginDialog?.();
          return;
        }
        case 'requestToken': {
          completeTokenRequest(event.source, event.origin);
          return;
        }
        }
      } catch (err) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
        //  console.error(err);
        }
      }
    });

    let tokenReceivedHandler;
    swagSdkEventListener.on('sendToken', tokenReceivedHandler = async () => {
      shouldSendTokenRef.current = true;
    });

    return () => {
      window.removeEventListener('message', messageHandler);
      swagSdkEventListener.off('sendToken', tokenReceivedHandler);
    };
  });

  useEffect(() => {
    if (!authState.ready) return;

    const childWindow = document
      .getElementsByClassName('GamePlayer__Game')[ 0 ]
      ?.getElementsByTagName('object')[ 0 ]
      ?.contentWindow;

    if (!childWindow) return;

    shouldSendTokenRef.current = false;
    completeTokenRequest(childWindow, '*');
  }, [ authState.ready ]);
}
