import { useEffect, useState, useRef } from 'react';

import { If, toClassName, createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import Flex from '@/common/components/Flex';
import Spacer, { SpacerImage } from '@/common/components/Spacer';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useSupportsTouch } from '@/common/hooks/supportsTouch';
import Transition from './Transition';
import { EventListener, EventMap } from '@/util/eventListener';
declare var ramp: any;
declare var Bolt: any;

enum GamePreviewState {
  WAITING = 'waiting',
  INTERSTITIAL = 'interstitial',
  LOADING = 'loading',
  HIDDEN = 'hidden',
  ERROR = 'error'
}

type ClickPlayHandler = (loadGame: () => void) => void;

interface GamePlayerProps extends IntrinsicProps {
  previewImage: string;
  gameSrc: string;
  autoPlay?: boolean;
  boundingBox?: DOMRect;
  fullScreen?: boolean;
  width?: number;
  height?: number;
  isFlash?: boolean;
  onClickPlay: ClickPlayHandler;
  onClickExitFullScreen?: () => void;
  onClickEnterFullScreen?: () => void;
  supportsMobile?: {
    portrait?: boolean;
    landscape?: boolean;
  };
  spacer?: SpacerImage;
  fluid?: boolean;
  gameOverlayTransition?: string;
  gameOverlayTransitionTimeout?: number;
  bottomPromoTransition?: string;
  bottomPromoTransitionTimeout?: number;
  hideFullScreenCloseButton?: boolean;
  previewTransition?: any;
  resetOnExitFullScreen?: boolean | 'mobile';
}

const gamePlayerStates = [
  'fluid'
];

export type MobileEvents = EventMap & {
  'play': (event: {}) => void;
  'exitFullScreen': (event: {}) => void;
  'fullScreenChange': (event: { fullScreen: boolean }) => void;
};

class MobileHeaderEventListener extends EventListener<MobileEvents> {}
export const gamePlayerEventListener = new MobileHeaderEventListener();

export default createComponent<GamePlayerProps>('GamePlayer', { classStates: gamePlayerStates }, function GamePlayer ({ className, mergeClassNames, style, slots }, props) {
  // Local states and refs
  const [ showGame, setShowGame ] = useState(false);
  const [ previewState, setPreviewState ] = useState(GamePreviewState.WAITING);
  const el = useRef<HTMLDivElement>(null);
  const objectEl = useRef<HTMLObjectElement>(null);
  const [ fullScreen, setFullScreen ] = useState(props.fullScreen);
  const router = useRouter();
  const supportsTouch = useSupportsTouch();
  const initialRender = useRef(true);
  
  // Guard refs to prevent duplicate executions of the ad flow and event handlers
  const adFlowStarted = useRef(false);
  const adEventHandled = useRef(false);

  // Ensure we exit fullscreen on route changes
  useEffect(() => {
    if (router.asPath.includes('play=instant')) return;

    const onRouteChange = () => setFullScreen(false);
    router.events.on('routeChangeStart', onRouteChange);
    return () => {
      router.events.off('routeChangeStart', onRouteChange);
    };
  }, [ router.events ]);

  const previewStyles = {
    backgroundImage: `url(${props.previewImage})`,
    zIndex: -2,
  };

  // Ad and game loading logic
  const loadGame = () => {
    console.log('LOADED GAME');
    setPreviewState(GamePreviewState.LOADING);
    setShowGame(true);
  };

  const removeAdContainer = () => {
    const adContainer = document.querySelector('div.GamePlayer_VidContainer');
    if (adContainer) {
      adContainer.remove();
    }
  };

  // Watch for changes in previewState to trigger the ad flow once
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    // When in INTERSTITIAL state, run the ad flow only once
    if (previewState === GamePreviewState.INTERSTITIAL) {
      if (adFlowStarted.current) return;
      adFlowStarted.current = true;

      // Invoke the onClickPlay callback with our ad/game logic
      props.onClickPlay(() => {
        if (typeof ramp.addUnits !== 'function') {
          console.log('ramp.addUnits did not load');
          loadGame();
          return;
        }

        ramp.addUnits([{ type: 'precontent_ad_video' }])
          .then(() => {
            if (typeof ramp.displayUnits === 'function') {
              ramp.displayUnits();
            } else {
              console.error('ramp.displayUnits is not a function');
              removeAdContainer();
              loadGame();
            }
          })
          .catch(err => {
            console.error('Error adding units:', err);
            removeAdContainer();
            loadGame();
          });

        // Create the ad container in the DOM
        const vidContainer = document.createElement('div');
        vidContainer.className = 'GamePlayer_VidContainer';
        vidContainer.style.position = 'absolute';
        vidContainer.style.zIndex = '3';
        vidContainer.style.width = '100%';
        vidContainer.style.height = '100%';
        vidContainer.style.top = '0';
        vidContainer.style.left = '0';

        const innerDiv = document.createElement('div');
        innerDiv.className = 'GamePlayer_VidContainer_Inner';
        vidContainer.appendChild(innerDiv);

        const target = document.querySelector('.GamePlayer');
        if (target) {
          target.appendChild(vidContainer);

          let elapsedTime = 0;
          const intervalTime = 500; // Check every 500ms
          const maxTime = 5000; // Timeout after 5 seconds

          const checkInterval = setInterval(() => {
            if (ramp?.settings?.slots?.precontent_ad_video) {
              console.log("precontent_ad_video detected, proceeding with ads.");
              clearInterval(checkInterval);

              // Attach ad event listeners and ensure only one event fires the loadGame()
              window.ramp.onPlayerReady = function() {
                const handleAdEvent = (eventName: string) => {
                  return () => {
                    if (adEventHandled.current) return;
                    adEventHandled.current = true;
                    console.log(`${eventName} Fired`);
                    removeAdContainer();
                    loadGame();
                  };
                };

                Bolt.on('precontent_ad_video', Bolt.BOLT_AD_COMPLETE, handleAdEvent('BOLT_AD_COMPLETE'));
                Bolt.on('precontent_ad_video', Bolt.BOLT_AD_ERROR, handleAdEvent('BOLT_AD_ERROR'));
                Bolt.on('precontent_ad_video', Bolt.BOLT_AD_CLOSE, handleAdEvent('BOLT_AD_CLOSE'));
              };
            } else {
              elapsedTime += intervalTime;
              if (elapsedTime >= maxTime) {
                console.log("precontent_ad_video not found. Likely AdBlock is active. Running loadGame().");
                clearInterval(checkInterval);
                removeAdContainer();
                loadGame();
              }
            }
          }, intervalTime);
        }
      });
    }
  }, [previewState, props.onClickPlay]);

  // Update game object element when gameSrc changes
  useEffect(() => {
    if (!objectEl.current) return;
    const { isFlash, gameSrc } = props;
    if (!isFlash) objectEl.current.setAttribute('data', gameSrc);
  }, [ showGame, props.isFlash, props.gameSrc ]);

  // Auto-play support and event listeners for external play/fullscreen triggers
  useEffect(() => {
    if (props.autoPlay) loadGame();
  }, [ props.autoPlay ]);

  useEffect(() => {
    const playListener = gamePlayerEventListener.on('play', () => {
      loadGame();
    });

    const exitFullScreenListener = gamePlayerEventListener.on('exitFullScreen', () => {
      setFullScreen(false);
    });

    return () => {
      gamePlayerEventListener.off('play', playListener);
      gamePlayerEventListener.off('exitFullScreen', exitFullScreenListener);
    };
  }, []);

  // Cleanup for ruffle scripts on unmount
  useEffect(() => {
    return () => {
      document.querySelector('#ruffle-config')?.remove();
      document.querySelector('#ruffle-load')?.remove();
    };
  }, []);

  // Reset player state if the game changes (and reset ad flow guards)
  useEffect(() => {
    setShowGame(false);
    setPreviewState(GamePreviewState.WAITING);
    adFlowStarted.current = false;
    adEventHandled.current = false;
  }, [ props.gameSrc ]);

  // Click handler for the preview overlay
  const clickPlay = () => {
    if (props.onClickPlay) {
      setPreviewState(GamePreviewState.INTERSTITIAL);
    } else {
      setShowGame(true);
    }
  };

  // When the game object loads, hide the preview overlay
  const gameLoaded = (evt) => {
    setPreviewState(GamePreviewState.HIDDEN);
  };

  // Fullscreen and bounding box styling
  const boundingBox = {
    top: props.boundingBox?.top || 0,
    left: props.boundingBox?.left || 0,
    right: props.boundingBox?.right || 0,
    bottom: props.boundingBox?.bottom || 0
  };

  useEffect(() => {
    setFullScreen(props.fullScreen);
  }, [ props.fullScreen ]);

  // Fullscreen browser API events
  useEffect(() => {
    const handler = () => {
      const doc = document as any;
      const fullScreenElement = 
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.webkitCurrentFullScreenElement ||
        doc.msFullscreenElement;
      setFullScreen(!!fullScreenElement);
    };

    document.documentElement.addEventListener('fullscreenchange', handler);
    document.documentElement.addEventListener('webkitfullscreenchange', handler);

    return () => {
      document.documentElement.removeEventListener('fullscreenchange', handler);
      document.documentElement.removeEventListener('webkitfullscreenchange', handler);
    };
  }, []);

  // Handle built-in fullScreen state changes, including triggering browser APIs
  {
    const { onClickEnterFullScreen, onClickExitFullScreen } = props;
    useEffect(() => {
      gamePlayerEventListener.trigger('fullScreenChange', { fullScreen });

      if (fullScreen) {
        if (onClickEnterFullScreen) onClickEnterFullScreen();
        document.body.classList.add('--fullScreen');
      } else {
        if (onClickExitFullScreen) onClickExitFullScreen();
        document.body.classList.remove('--fullScreen');
        if ((props.resetOnExitFullScreen === 'mobile' && supportsTouch) || (props.resetOnExitFullScreen === true)) {
          setShowGame(false);
          setPreviewState(GamePreviewState.WAITING);
        }
      }

      if (supportsTouch || !el.current) return;

      const doc = document as any;
      const fullScreenElement = 
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.webkitCurrentFullScreenElement ||
        doc.msFullscreenElement;

      if (fullScreen && !fullScreenElement) {
        const el = document.documentElement as any;
        const requestFullscreen = 
          el.requestFullscreen || 
          el.webkitRequestFullScreen ||
          el.webkitRequestFullscreen ||
          el.mozRequestFullScreen || 
          el.msRequestFullscreen;
        requestFullscreen.call(el, { navigationUI: 'hide' });
      } 
      
      if (!fullScreen && fullScreenElement) {
        const exitFullscreen = 
          doc.exitFullscreen || 
          doc.mozCancelFullScreen || 
          doc.webkitExitFullscreen || 
          doc.msExitFullscreen;
        exitFullscreen.call(doc);
      }
    }, [ fullScreen ]);
  }

  const gameStyles = {
    width: props.width,
    height: props.height
  };

  style = {
    ...style,
    ...(fullScreen ? boundingBox : gameStyles)
  };

  className = mergeClassNames(
    toClassName('GamePlayer', {
      fullScreen,
      staticSize: !!props.width && !!props.height,
      isPlaying: showGame,
    }), 
    className
  );

  const [ orientation, setOrientation ] = useState<'landscape' | 'portrait' | null>(null);
  useEffect(() => {
    const orientationHandler = () => {
      const { innerWidth, innerHeight } = window;
      const isLandscape = innerWidth > innerHeight;
      setOrientation(isLandscape ? 'landscape' : 'portrait');
    };

    window.addEventListener('resize', orientationHandler);
    window.addEventListener('orientationchange', orientationHandler);
    orientationHandler();

    return () => {
      window.removeEventListener('resize', orientationHandler);
      window.removeEventListener('orientationchange', orientationHandler);
    };
  }, []);

  return (
    <div className={className} style={style}>
      {If((showGame && props.isFlash), () => (
        <>
          <Script 
            id="ruffle-load" 
            src="/scripts/ruffle/2021-04-04/ruffle.js" 
            strategy="lazyOnload"
            onLoad={gameLoaded}
          />
          <Script
            id="ruffle-config" 
            dangerouslySetInnerHTML={{
              __html: `
                window.RufflePlayer = window.RufflePlayer || {};
                window.RufflePlayer.config = {
                  "publicPath": undefined,
                  "polyfills": true,
                  "autoplay": "auto",
                  "unmuteOverlay": "visible",
                  "backgroundColor": null,
                  "letterbox": "on",
                  "warnOnUnsupportedContent": true,
                  "contextMenu": true,
                  "upgradeToHttps": true,
                  "maxExecutionDuration": {"secs": 15, "nanos": 0},
                };
              `
            }}
            strategy="afterInteractive"
          />
        </>
      )).EndIf()}
      
      <Transition 
        visible={previewState !== GamePreviewState.HIDDEN} 
        leave={props.previewTransition} 
        className='GamePlayer__Overlay'
        style={{ zIndex: -2 }}
      >
        <div className={`GamePlayer__PreviewOverlay ${!props.supportsMobile ? 'GamePlayer__PreviewOverlay--remove' : ''}`} style={previewStyles} />
        {If(previewState === GamePreviewState.WAITING, () => (
          <Flex key='playButton' alignCenter justifyCenter className='GamePlayer__Overlay' style={{ zIndex: 'initial' }}>
            <div onClick={clickPlay}>
              {slots.playButton}
            </div>
          </Flex>
        ))
          .ElseIf(previewState === GamePreviewState.INTERSTITIAL, () => (
            <Flex key='interstitial' alignCenter justifyCenter className='GamePlayer__Overlay' style={{ zIndex: 'initial' }}>
              {slots.interstitial}
            </Flex>
          ))
          .ElseIf(previewState === GamePreviewState.LOADING, () => (
            <Flex key='loader' alignCenter justifyCenter className='GamePlayer__Overlay' style={{ zIndex: 'initial' }}>
              <div className='GamePlayer__Loader' />
            </Flex>
          ))
          .ElseIf(previewState === GamePreviewState.ERROR, () => (
            <Flex key='error' alignCenter justifyCenter className='GamePlayer__Incompatible'>
              {slots.error ? slots.error : 'An error occurred while loading this game. Please try again later.'}
            </Flex>
          ))
          .EndIf()}
        <Transition 
          visible={previewState !== GamePreviewState.HIDDEN && previewState !== GamePreviewState.ERROR} 
          leave={{ translateY: '150%', opacity: 0 }} 
          className={toClassName('GamePlayer__Anchor', '&--bottom')} 
          style={{ width: '100%' }}
        >
          <Flex justifyCenter wide>
            <div>
              {slots.bottomPromo}
            </div>
          </Flex>
        </Transition>
      </Transition>

      <div className='GamePlayer__Game' style={gameStyles}>
        {If(showGame || props.autoPlay, () => (
          <>
            {If(props.isFlash, () => (
              <object 
                ref={objectEl}
                width={props.width ? props.width : '100%'} 
                height={props.height ? props.height : '100%'}
              >
                <param value={props.gameSrc} />
                <embed src={props.gameSrc} />
              </object>
            )).Else(() => (
              <object 
                ref={objectEl} 
                type="text/html"
                width={props.width ? props.width : '100%'} 
                height={props.height ? props.height : '100%'}
                onLoad={gameLoaded}
              />
            )).EndIf()}
          </>
        )).EndIf()}
        {If(props.spacer && !props.width, () => (
          <Spacer wide spacer={props.spacer} />
        )).EndIf()}
      </div>
      {If(!props.supportsMobile, () => (
        <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--mobile')}>
          {slots.mobileUnsupported ? slots.mobileUnsupported : 'This game does not support mobile devices.'}
        </Flex>
      ))
        .Else(() => (
          <>
            {If((showGame || props.autoPlay) && orientation === 'landscape' && !props.supportsMobile.landscape, () => (
              <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--landscape')}>
                {slots.landscapeUnsupported ? slots.landscapeUnsupported : 'Rotate the device into portrait mode to play this game.'}
              </Flex>
            )).EndIf()}
            {If((showGame || props.autoPlay) && orientation === 'portrait' && !props.supportsMobile.portrait, () => (
              <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--portrait')}>
                {slots.portraitUnsupported ? slots.portraitUnsupported : 'Rotate the device into landscape mode to play this game.'}
              </Flex>
            )).EndIf()}
          </>
        )).EndIf()}
      {If(fullScreen && (previewState === GamePreviewState.WAITING || showGame), () => (
        <div className={toClassName('GamePlayer__Anchor', '&--top', '&--right')} style={{ zIndex: 4 }}>
          <Flex alignCenter justifyCenter>
            <span className='GamePlayer__CloseButton' onClick={() => setFullScreen(false)}>&times;</span>
          </Flex>
        </div>
      )).EndIf()}
    </div>
  );
});
